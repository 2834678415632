@charset "utf-8";

ul,
ol {
    -webkit: {
        margin: {
            before: 0;
            after: 0;
        }
        padding-start: 0;
    }
}
