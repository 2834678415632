$color: #3e3e3e;
$bg-color: #fff;
$main-color: #ff9b2d;
$main-color_d: darken($main-color, 10%);;
$main-color_l: lighten($main-color, 35%);;
$main-color_l2: lighten($main-color, 25%);;
$main-color_w: #fff;
$main-color_g: #009266;
$main-color_gray: #eee;
$main-color_gray2: darken($main-color_gray, 20%);;
$navbar-height: 88.55px;
$footer-height: 32px;
