@charset "UTF-8";

.navbar {
  .nav-item {
    .nav-link {
      position: relative;
      margin-right: $spacer * .5;
      &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 0;
        margin: 0 auto;
        content: "";
        border-bottom-style: solid;
        border-bottom-width: 1px;
        transition: width .2s;
      }
      @include hover-focus {
        &::before {
          width: 100%;
        }
      }
    }
    &.active .nav-link::before {
      width: 100%;
    }
  }
}

.navbar-dark {
  .nav-item .nav-link::before {
    border-color: $navbar-dark-active-color;
  }
}

.navbar-light {
  .nav-item .nav-link::before {
    border-color: $navbar-light-active-color;
  }
}
