@charset "utf-8";
$main-color_l: lighten($main-color, 35%);
$main-color_l2: lighten($main-color, 25%);
$main-color2: darken($main-color, 8%);
$main-color3: darken($main-color, 15%);
$accent-color: adjust-hue($main-color, 150deg);
$link-color: adjust-hue($color, 210deg);
$link-v-color: darken($link-color, 15%);
$link-ha-color: darken($link-color, 8%);
$background-position: center center;
$eyecatch-height: 100vh;
$line-height: 1.6;
$letter-spacing: 0.15rem;
$btn_size: 300px;
