@charset "utf-8";

//trasition
@mixin t() {
    transition: all 0.3s ease;
}

//font: round gothic
@mixin marugo() {
    font-family: YuGothic, "游ゴシック体", "Yu Gothic Medium", "游ゴシック Medium", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ヒラギノ丸ゴ Pro W4", "Hiragino Maru Gothic Pro", "HGMaruGothicMPRO", sans-serif;
}

//font: serif
@mixin mincho() {
    font-family: YuGothic, "游ゴシック体", "Yu Gothic Medium", "游ゴシック Medium", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ヒラギノ丸ゴ Pro W4", "Hiragino Maru Gothic Pro", "Shuei MaruGo L", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝",
        "ＭＳ 明朝", serif;
}

@mixin fontsize_main() {
    font-size: 1rem;
    @include media-breakpoint-down(md) {
        font-size: 0.9em;
    }
}

//eyecatch background-image
@mixin eyecatchBGImage($imgPath, $background-position) {
    background: {
        image: url($imgPath);
        position: $background-position;
    }
}

//eyecatch text
@mixin eyecatchText($fontSize) {
    color: $main-color;
    z-index: 3;
    font-size: $fontSize;
    @include mincho();
}
